import React from "react";
import { Box, ColorScheme, Spacing, Tag } from "@noom/wax-component-library";

type EnvIndicatorInfo = {
  tagText: string;
  tagColorScheme: ColorScheme;
};

/**
 * Configure your env domains here.
 *
 * Don't use this for local development, as this should just be an indicator
 * of an environment where configuration is well known.
 */
const ENV_INDICATORS: Record<string, EnvIndicatorInfo> = {
  "dev.wsli.dev": { tagText: "DEV", tagColorScheme: ColorScheme.success },
  "test.wsli.dev": { tagText: "TEST", tagColorScheme: ColorScheme.warning },
  "prod.wsli.dev": { tagText: "PROD", tagColorScheme: ColorScheme.error },
};

export const EnvironmentTag: React.FC = () => {
  const { hostname } = window.location;
  const envMatch = Object.keys(ENV_INDICATORS).find(
    (key) => hostname.indexOf(key) !== -1
  );

  return (
    <Box marginRight="auto" marginLeft={Spacing[3]} marginTop={Spacing[1]}>
      {envMatch && (
        <Tag colorScheme={ENV_INDICATORS[envMatch].tagColorScheme}>
          {ENV_INDICATORS[envMatch].tagText}
        </Tag>
      )}
    </Box>
  );
};
