/**
 * Top level component
 *
 * Copyright (C) 2020A Noom, Inc.
 * @author nikola
 */

import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider, LoaderView } from "@noom/wax-component-library";

import { MainLayout } from "@modules/layout";
import HomePage from "@pages/HomePage";

import theme from "./theme";

const App: React.FC = () => {
  return (
    <ThemeProvider includeFonts resetCSS theme={theme}>
      <BrowserRouter>
        <Suspense fallback={<LoaderView />}>
          <MainLayout>
            <Routes>
              <Route path="/" element={<HomePage />} />
            </Routes>
          </MainLayout>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
