import { CompensationModel, EquityGrantModel } from "./model";

const storageKey = "COMPENSATION_MODEL";

const defaultModel: CompensationModel = {
  baseSalary: 100000,
  level: "L1",
  country: "USA",
  equityGrants: [],
  targetBonusPercentage: 7.5,
  sharePrice: 31.39,
};

export function saveToLocalStorage(model: CompensationModel) {
  localStorage.setItem(storageKey, JSON.stringify(model));
}

export function getFromLocalStorageOrDefault(): CompensationModel {
  const storedModel = localStorage.getItem(storageKey);
  if (storedModel) {
    // We have to explicitly serialize dates so they re-appear properly
    const parsedModel = JSON.parse(storedModel) as CompensationModel;
    return {
      ...parsedModel,
      equityGrants: parsedModel.equityGrants.map((grant) => ({
        ...grant,
        firstVestDate: new Date(grant.firstVestDate),
      })),
    };
  }
  return defaultModel;
}

export function clampToNextVestDate(d: Date): Date {
  // Vest months are 3, 6, 9, 12, so we basically want to round up to the nearest multiple of 3
  const month = Math.ceil((d.getMonth() + 1) / 3) * 3 - 1;
  // Vest dates are always on the 20th day
  return new Date(d.getFullYear(), month, 20);
}

export function addMonths(d: Date, n: number): Date {
  return new Date(d.getFullYear(), d.getMonth() + n, d.getDate());
}

export function getDefaultEquityGrant(): EquityGrantModel {
  return {
    shareCount: 0,
    strikePrice: 0,
    grantType: "newHire",
    firstVestDate: clampToNextVestDate(new Date()),
  };
}

export function getAmountVestedOnDate(
  sharePrice: number,
  data: EquityGrantModel,
  date: Date
): {
  sharesVested: number;
  amountVested: number;
} {
  const upcomingVestDate = clampToNextVestDate(date);
  let vestYears;
  if (data.grantType === "newHire") {
    vestYears = 4;
  } else if (data.grantType === "refresher") {
    vestYears = 2;
  } else {
    vestYears = 1;
  }
  const firstVestDate = clampToNextVestDate(data.firstVestDate);
  const lastVestDate = clampToNextVestDate(
    addMonths(
      data.firstVestDate,
      data.grantType === "newHire" ? 39 : vestYears * 12
    )
  );

  if (upcomingVestDate < firstVestDate || upcomingVestDate >= lastVestDate) {
    return { sharesVested: 0, amountVested: 0 };
  }
  const percentVestedQuarterly =
    data.grantType === "newHire" &&
    upcomingVestDate.valueOf() === firstVestDate.valueOf()
      ? 0.25
      : 1 / (vestYears * 4);
  const sharesVested = data.shareCount * percentVestedQuarterly;
  const amountVested = sharesVested * Math.max(sharePrice - data.strikePrice, 0);
  return { sharesVested, amountVested };
}

export function getAmountVestedInYear(
  sharePrice: number,
  data: EquityGrantModel,
  year: number
): number {
  const vestDates = [
    new Date(year, 2, 20),
    new Date(year, 5, 20),
    new Date(year, 8, 20),
    new Date(year, 11, 20),
  ];

  let value = 0;
  vestDates.forEach((d) => {
    value += getAmountVestedOnDate(sharePrice, data, d).amountVested;
  });
  console.log(year, value);
  return value;
}
