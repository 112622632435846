/**
 * Main layout component
 *
 * Copyright (C) 2020A Noom, Inc.
 * @author nikola
 */

import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@noom/wax-component-library";
import NavBar from "./NavBar";

const MainLayout: React.FC = ({ children }) => {
  const { t } = useTranslation();

  return (
    <Box w="100%" h="100%" d="flex" flexDir="column">
      <header>
        <NavBar />
      </header>

      <Box as="main" maxW="container.xl" m="0 auto" flexGrow={1}>
        {children}
      </Box>

      <Box as="footer" textAlign="center" p={4} mt="auto" />
    </Box>
  );
};

export default MainLayout;
