/**
 * Main front end point of entry.
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
 */

import React from "react";
import ReactDOM from "react-dom";
import App from "@app/App";
import { throwIfNullOrUndefined } from "@modules/common";
import { MixpanelLogger } from "@noom/hive-analytics";

// Make sure to set the token environment variable
const mixpanelToken = throwIfNullOrUndefined(
  process.env.HIVE_MIXPANEL_TOKEN,
  "process.env.HIVE_MIXPANEL_TOKEN"
);

if (process.env.HIVE_USE_MOCK_SERVER_API) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
  const { worker: mswWorker } = require("./mocks/mswBrowser");
  mswWorker.start();
}

// Render the app
ReactDOM.render(
  <MixpanelLogger
    token={mixpanelToken}
    isDev={process.env.NODE_ENV === "development"}
  >
    <App />
  </MixpanelLogger>,
  document.getElementById("root")
);
