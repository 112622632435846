import { extendCompassTheme, CompassColor } from "@noom/wax-component-library";

// More info about theme customization
const theme = extendCompassTheme({
  styles: {
    global: {
      "html, body, #root": {
        bg: CompassColor.sand01,
        color: CompassColor.black,
        h: "100%",
      },
      h2: {
        fontSize: "2xl",
        fontWeight: "bold",
      },
      h3: {
        fontSize: "lg",
        fontWeight: "bold",
      },
      h4: {
        fontSize: "md",
        fontWeight: "bold",
        color: "#555",
      },
      "td, th": {
        padding: "3px",
      },
      th: {
        color: "#555",
      },
    },
  },
});

export default theme;
