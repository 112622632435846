type LevelData = {
  targetBonusPercentage: number;
};

export const GrantType: Record<string, string> = {
  newHire: "4 years; 1 year cliff",
  refresher: "2 years",
  adjustment: "1 year",
};

export const Levels: Record<string, LevelData> = {
  L1: { targetBonusPercentage: 7.5 },
  L2: { targetBonusPercentage: 10 },
  L3: { targetBonusPercentage: 10 },
  L4: { targetBonusPercentage: 10 },
  L5: { targetBonusPercentage: 15 },
  L6: { targetBonusPercentage: 20 },
  L7: { targetBonusPercentage: 20 },
  L8: { targetBonusPercentage: 25 },
};

export const Countries = {
  Argentina: { perkMultiplier: 0.4 },
  Australia: { perkMultiplier: 0.7 },
  Brazil: { perkMultiplier: 0.5 },
  Canada: { perkMultiplier: 0.9 },
  Croatia: { perkMultiplier: 0.7 },
  Germany: { perkMultiplier: 0.7 },
  Ireland: { perkMultiplier: 0.9 },
  Japan: { perkMultiplier: 0.6 },
  Netherlands: { perkMultiplier: 0.8 },
  Poland: { perkMultiplier: 0.7 },
  Romania: { perkMultiplier: 0.6 },
  Spain: { perkMultiplier: 0.6 },
  Switzerland: { perkMultiplier: 0.9 },
  USA: { perkMultiplier: 1 },
  UK: { perkMultiplier: 1 },
};

export type EquityGrantModel = {
  shareCount: number;
  strikePrice: number;
  grantType: string;
  firstVestDate: Date;
};

export type CompensationModel = {
  baseSalary: number;
  level: string;
  targetBonusPercentage: number;
  equityGrants: Array<EquityGrantModel>;
  sharePrice: number;
  country: string;
};

export type EquityOutlook = {
  date: Date;
  amountVestedToday: number;
  amountVestedCumulatively: number;
  sharesVestedToday: number;
  sharesVestedCumulatively: number;
};
