import React, { ChangeEvent, useEffect, useState, useRef } from "react";
import { useGraphHook } from "@modules/graphs/CompensationGraph";
import {
  Box,
  Button,
  Datepicker,
  Grid,
  NumberInput,
  Select,
  Stack,
  Text,
  Tooltip,
} from "@noom/wax-component-library";
import {
  addMonths,
  clampToNextVestDate,
  getAmountVestedOnDate,
  getDefaultEquityGrant,
  getFromLocalStorageOrDefault,
  saveToLocalStorage,
} from "@modules/compensation/api";
import {
  CompensationModel,
  EquityGrantModel,
  EquityOutlook,
  GrantType,
  Levels,
} from "@modules/compensation/model";

const DataDisplay: React.FC<DataDisplayProps> = ({ data }) => {
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const minVestDate = data.equityGrants
    .map((g) => clampToNextVestDate(g.firstVestDate))
    .reduce((a, b) => (a < b ? a : b), clampToNextVestDate(new Date()));
  const equityOutlook: Array<EquityOutlook> = [];
  for (let i = 0; i < 16; i += 1) {
    const date = addMonths(minVestDate, i * 3);
    const amountVestedToday = data.equityGrants.reduce(
      (total: number, grant) =>
        total +
        getAmountVestedOnDate(data.sharePrice, grant, date).amountVested,
      0
    );
    const sharesVestedToday = data.equityGrants.reduce(
      (total: number, grant) =>
        total +
        getAmountVestedOnDate(data.sharePrice, grant, date).sharesVested,
      0
    );
    const amountVestedCumulatively =
      (i === 0 ? 0 : equityOutlook[i - 1].amountVestedCumulatively) +
      amountVestedToday;
    const sharesVestedCumulatively =
      (i === 0 ? 0 : equityOutlook[i - 1].sharesVestedCumulatively) +
      sharesVestedToday;
    equityOutlook.push({
      date,
      amountVestedToday,
      amountVestedCumulatively,
      sharesVestedToday,
      sharesVestedCumulatively,
    });
  }

  return (
    <table>
      <thead>
        <tr>
          <th>Date</th>
          <th>Shares vested today</th>
          <th>Amount vested today</th>
          <th>Amount vested cumulatively</th>
        </tr>
      </thead>
      <tbody>
        {equityOutlook.map(
          ({
            date,
            amountVestedToday,
            amountVestedCumulatively,
            sharesVestedToday,
          }) => {
            return (
              <tr>
                <td>{date.toLocaleDateString("en-CA")}</td>
                <td>{sharesVestedToday}</td>
                <td>{currencyFormatter.format(amountVestedToday)}</td>
                <td>{currencyFormatter.format(amountVestedCumulatively)}</td>
              </tr>
            );
          }
        )}
      </tbody>
    </table>
  );
};

interface TooltipProps {
  label: string;
  children: string;
}

const HeaderWithTooltip: React.FC<TooltipProps> = ({ label, children }) => (
  <Box display="flex" justifyContent="space-between">
    <h3>{children}</h3>
    {label && (
      <Tooltip label={label}>
        <h3 style={{ color: "var(--chakra-colors-gray-700)" }}>ℹ</h3>
      </Tooltip>
    )}
  </Box>
);

const TextWithTooltip: React.FC<TooltipProps> = ({ label, children }) => (
  <Box display="flex" justifyContent="space-between">
    <h4>{children}</h4>
    {label && (
      <Tooltip label={label}>
        <h3 style={{ color: "var(--chakra-colors-gray-700)" }}>ℹ</h3>
      </Tooltip>
    )}
  </Box>
);

const HomePage: React.FC = () => {
  const [data, setData] = useState(getFromLocalStorageOrDefault());

  useEffect(() => {
    saveToLocalStorage(data);
  }, [data]);

  const handleBaseSalaryChange = (
    valueAsString: string,
    valueAsNumber: number
  ) => {
    setData((priorState) => ({
      ...priorState,
      baseSalary: Number.isNaN(valueAsNumber) ? 0 : valueAsNumber,
    }));
  };

  const handleLevelChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const newLevel = e.target.value.toString();
    setData((priorState) => ({
      ...priorState,
      level: newLevel,
      targetBonusPercentage: Levels[newLevel].targetBonusPercentage,
    }));
  };

  const handleTargetBonusChange = (
    valueAsString: string,
    valueAsNumber: number
  ) => {
    setData((priorState) => ({
      ...priorState,
      targetBonusPercentage: Number.isNaN(valueAsNumber) ? 0 : valueAsNumber,
    }));
  };

  const handleSharePriceChange = (
    valueAsString: string,
    valueAsNumber: number
  ) => {
    setData((priorState) => ({
      ...priorState,
      sharePrice: Number.isNaN(valueAsNumber) ? 0 : valueAsNumber,
    }));
  };

  const handleGrantChange = (
    idx: number,
    getNewGrant: (grant: EquityGrantModel) => EquityGrantModel
  ) => {
    setData((priorState) => ({
      ...priorState,
      equityGrants: priorState.equityGrants.map((grant, i) =>
        idx !== i ? grant : getNewGrant(grant)
      ),
    }));
  };

  const handleShareCountChange = (n: number, idx: number) =>
    handleGrantChange(idx, (g) => ({
      ...g,
      shareCount: Number.isNaN(n) ? 0 : n,
    }));
  const handleStrikePriceChange = (n: number, idx: number) =>
    handleGrantChange(idx, (g) => ({
      ...g,
      strikePrice: Number.isNaN(n) ? 0 : n,
    }));
  const handleFirstVestDateChange = (d: Date, idx: number) =>
    handleGrantChange(idx, (g) => ({ ...g, firstVestDate: d }));
  const handleGrantTypeChange = (s: string, idx: number) =>
    handleGrantChange(idx, (g) => ({ ...g, grantType: s }));

  const addNewEquityGrant = (grant: EquityGrantModel) => {
    setData((priorState) => ({
      ...priorState,
      equityGrants: [...priorState.equityGrants, grant],
    }));
  };

  const deleteEquityGrant = (idx: number) => {
    setData((priorState) => ({
      ...priorState,
      equityGrants: priorState.equityGrants.filter((v, i) => i !== idx),
    }));
  };

  const d3Container = useRef<SVGSVGElement | null>(null);
  useGraphHook({ data, d3Container });

  return (
    <>
      <Grid margin="20px 0" gap="40px" gridTemplateColumns="repeat(4, 1fr)">
        <Box>
          <HeaderWithTooltip label="">Base Salary</HeaderWithTooltip>
          <NumberInput
            name="baseSalary"
            defaultValue={data.baseSalary}
            onChange={handleBaseSalaryChange}
          />
        </Box>
        <Box>
          <HeaderWithTooltip label="">Level</HeaderWithTooltip>
          <Select
            name="level"
            value={data.level}
            options={Object.keys(Levels).map((k) => ({ label: k, value: k }))}
            onChange={handleLevelChange}
          />
        </Box>
        <Box>
          <HeaderWithTooltip label="Represents the target bonus as a percentage of base salary for a given year. This assumes 100% company bonus pool funding. In the majority of cases, this is determined by your level.">
            Target Bonus (%)
          </HeaderWithTooltip>
          <NumberInput
            name="targetBonus"
            value={data.targetBonusPercentage}
            onChange={handleTargetBonusChange}
          />
        </Box>
        <Box>
          <HeaderWithTooltip label="Represents how much each equity share is worth. You can adjust this to see how the value of your equity grant(s) change when the share price changes. Noom's current 409A FMV is [REDACTED]. REMINDER: Any value assumption we make about our equity is just an estimate as long as we're a private company.">
            Share Price
          </HeaderWithTooltip>
          <NumberInput
            defaultValue={data.sharePrice}
            onChange={handleSharePriceChange}
          />
        </Box>
      </Grid>
      <Stack gap="5px">
        <h3>Equity Grants</h3>
        {data.equityGrants.length && (
          <Grid gridTemplateColumns="repeat(5, 1fr)">
            <h4>Vesting Schedule</h4>
            <h4>Shares</h4>
            <TextWithTooltip label="The predetermined price at which a stock option holder can buy the company's shares when exercising their stock options. This value is only relevant for option grants; if you have RSUs, set it to 0.">
              Strike price
            </TextWithTooltip>
            <TextWithTooltip label="This is the first date you receive shares (not the date you receive the grant).">
              First Vest Date
            </TextWithTooltip>
            <div />
            {data.equityGrants.map((grant, index) => (
              <>
                <Select
                  value={grant.grantType}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                    handleGrantTypeChange(e.target.value, index)
                  }
                  options={Object.keys(GrantType).map((k) => ({
                    value: k,
                    label: GrantType[k],
                  }))}
                />
                <NumberInput
                  value={grant.shareCount}
                  onChange={(s, n) => handleShareCountChange(n, index)}
                />
                <NumberInput
                  defaultValue={grant.strikePrice}
                  onChange={(s, n) => handleStrikePriceChange(n, index)}
                />
                <Datepicker
                  date={grant.firstVestDate}
                  onDateChange={(d: Date) =>
                    handleFirstVestDateChange(d, index)
                  }
                />
                <Button onClick={() => deleteEquityGrant(index)}>
                  Delete grant
                </Button>
              </>
            ))}
          </Grid>
        )}
        <Button onClick={() => addNewEquityGrant(getDefaultEquityGrant())}>
          Add equity grant
        </Button>
      </Stack>
      <Box display="flex" margin="20px 0" justifyContent="space-between">
        <Stack>
          <h3>Equity Outlook</h3>
          <DataDisplay data={data} />
        </Stack>
        <Stack gap="50px">
          <h3>Target Total Compensation (year over year)</h3>
          <svg
            className="comp-graph"
            width={500}
            height={400}
            ref={d3Container}
          />
        </Stack>
      </Box>
      <Text>
        Disclaimer: values and inputs shown are meant to be illustrative
        examples only and may not reflect actual compensation, which may be
        subject to change depending on a variety of factors including, but not
        limited to: company performance, external market conditions, individual
        employee circumstances (i.e. country/geo, level, hire date, employment
        type, performance, etc.). Any value assumption we make about our equity
        is just an estimate as long as we're a private company.
      </Text>
    </>
  );
};

interface DataDisplayProps {
  data: CompensationModel;
}

export default HomePage;
