/**
 * Simple nav bar component
 *
 * Copyright (C) 2022A Noom, Inc.
 * @author nikola
 */

import React from "react";
import { Box, CompassColor, Button } from "@noom/wax-component-library";

import { EnvironmentTag } from "@modules/layout/components/EnvironmentTag";

export type NavBarProps = { canLogout?: boolean; onLogout?: () => void };

const NavBar: React.FC<NavBarProps> = ({ canLogout = false, onLogout }) => {
  return (
    <Box
      display="flex"
      height="80px"
      margin="0px auto"
      width="100%"
      backgroundColor={CompassColor.white}
      zIndex="sticky"
      alignItems="center"
      justifyContent="center"
    >
      <Box maxW="container.xl" w="100%" d="flex" justifyContent="flex-end">
        <Box justifySelf="start" marginLeft={16}>
          <img src="/assets/img/noom-logo-black.svg" alt="noom" width="150px" />
        </Box>
        <EnvironmentTag />

        {canLogout && (
          <Box>
            <Button
              variant="outline"
              variantColor="secondary"
              color={CompassColor.black}
              textTransform="uppercase"
              onClick={onLogout}
              size="md"
              fontWeight="normal"
              marginRight={4}
            >
              Logout
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default NavBar;
